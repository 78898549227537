// export const lazyMintAddress = "0x6d60927151ba2605ad00b3519847c9213c9a9f5b";     // QA
// export const lazyMintAddress = "0xc570097F16d01aAf0BCa040E067A55F073DBdeAD";    // Produccion
export const lazyMintAddress = process.env.GATSBY_LAZYMINT_CONTRACT;
export const lazyMintAbi = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "previousAdmin",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newAdmin",
                "type": "address"
            }
        ],
        "name": "AdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "beacon",
                "type": "address"
            }
        ],
        "name": "BeaconUpgraded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint8",
                "name": "version",
                "type": "uint8"
            }
        ],
        "name": "Initialized",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "moralisId",
                "type": "string"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenAmount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "tokenContract",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "buyer",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "eventId",
                "type": "string"
            }
        ],
        "name": "StripeMint",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "implementation",
                "type": "address"
            }
        ],
        "name": "Upgraded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "moralisId",
                "type": "string"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenAmount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "tokenContract",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "buyer",
                "type": "address"
            }
        ],
        "name": "eventMint",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_collectionAddress",
                "type": "address"
            }
        ],
        "name": "computeAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getLatestPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_eventId",
                "type": "string"
            }
        ],
        "name": "getStripeEvent",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_rolesContracts",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_splitterDTC",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_pricefeed",
                "type": "address"
            }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "priceFeed",
        "outputs": [
            {
                "internalType": "contract AggregatorV3Interface",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "_uri",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_signer",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "_timeStamp",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_nftContract",
                        "type": "address"
                    }
                ],
                "internalType": "struct LazyMinting.Nft",
                "name": "_nftData",
                "type": "tuple"
            },
            {
                "internalType": "bytes",
                "name": "signature",
                "type": "bytes"
            },
            {
                "internalType": "string",
                "name": "_moralisId",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "_tokenAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_maxSupply",
                "type": "uint256"
            }
        ],
        "name": "primarySale",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "_uri",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_signer",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "_timeStamp",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_nftContract",
                        "type": "address"
                    }
                ],
                "internalType": "struct LazyMinting.Nft",
                "name": "_nftData",
                "type": "tuple"
            },
            {
                "internalType": "bytes",
                "name": "signature",
                "type": "bytes"
            },
            {
                "internalType": "string",
                "name": "_moralisId",
                "type": "string"
            }
        ],
        "name": "primarySale721",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "_uri",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_signer",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "_timeStamp",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_nftContract",
                        "type": "address"
                    }
                ],
                "internalType": "struct LazyMinting.Nft",
                "name": "_nftData",
                "type": "tuple"
            },
            {
                "internalType": "bytes",
                "name": "_signature",
                "type": "bytes"
            },
            {
                "internalType": "string",
                "name": "_moralisId",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "_toAddress",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "_eventId",
                "type": "string"
            }
        ],
        "name": "primarySale721Relayer",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "_uri",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_signer",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "_timeStamp",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_nftContract",
                        "type": "address"
                    }
                ],
                "internalType": "struct LazyMinting.Nft",
                "name": "_nftData",
                "type": "tuple"
            },
            {
                "internalType": "bytes",
                "name": "_signature",
                "type": "bytes"
            },
            {
                "internalType": "string",
                "name": "_moralisId",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "_toAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_tokenAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_maxSupply",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_eventId",
                "type": "string"
            }
        ],
        "name": "primarySaleRelayer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "proxiableUUID",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "roles",
        "outputs": [
            {
                "internalType": "contract IRoles",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_tokenAmount",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_moralisId",
                "type": "string"
            }
        ],
        "name": "secondaryMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_tokenAmount",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_moralisId",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "_toAddress",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "_eventId",
                "type": "string"
            }
        ],
        "name": "secondaryMintRelayer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_priceFeed",
                "type": "address"
            }
        ],
        "name": "setPriceFeed",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_rolesContract",
                "type": "address"
            }
        ],
        "name": "setRoles",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newSplitter",
                "type": "address"
            }
        ],
        "name": "setSplitterDTC",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "splitterDTC",
        "outputs": [
            {
                "internalType": "address payable",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokensSold",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            }
        ],
        "name": "upgradeTo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "upgradeToAndCall",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }
];