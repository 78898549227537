import React from "react"
import Header from '../components/header/header'
import Hr from '../components/hr/hr'
import Footer from '../components/footer/footer'
import "./layout.scss"
import Moralis from 'moralis-v1';
import { MoralisProvider } from "react-moralis";
import { UserProvider } from "../context/UserContext";
import { Script } from "gatsby";
import { NetworkProvider } from "../context/NetworkContext";
import { DTCsProvider } from "../context/DTCsContext";
import { ThemeProvider } from "../context/ThemeContext";
import RingLoader from "react-spinners/RingLoader";
import { Container } from 'react-bootstrap';
import { renderMetaTags } from 'react-datocms';
import { graphql } from 'gatsby'

export default function Layout({ children }) {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.src =
        "https://unpkg.com/@web3auth/web3auth@latest/dist/web3auth.umd.min.js";
      script.id = "web3auth";
      document.body.appendChild(script);
      script.onload = event => {
        new window.Web3auth.Web3Auth({
          clientId: process.env.GATSBY_WEB3_ID,
          chainConfig: {
            chainNamespace: "eip155",
            chainId: Moralis.Chains.POLYGON_MAINNET,
            rpcTarget: "https://polygon-rpc.com",
          },
        });
        setIsLoading(false);
      };
    }
  }, []);

  if (isLoading) return (<Container className="loader">
    <RingLoader color="#2a4379" />
  </Container>)

  return (<>
    <Script src="https://unpkg.com/@web3auth/web3auth@latest/dist/web3auth.umd.min.js" />
    <MoralisProvider appId={process.env.GATSBY_MORALIS_API} serverUrl={process.env.GATSBY_MORALIS_SERVER}>
      <UserProvider>
        <NetworkProvider>
          <DTCsProvider>
            <ThemeProvider>
              <Header />
              <Hr />
              {children}
              <Footer />
            </ThemeProvider>
          </DTCsProvider>
        </NetworkProvider>
      </UserProvider>
    </MoralisProvider>
  </>
  )
}

export const Head = ({ data }) => (
  renderMetaTags([...data.page.nodes[0].seoMetaTags, ...data.site.nodes[0].favicon])
)

export const query = graphql`
query CareersQuery {
  page: allDatoCmsCareer {
    nodes {
      seoMetaTags {
       tags
      }
    }
  }
  site: allDatoCmsSite{
    nodes{
      favicon: faviconMetaTags {
        tags
      }
    }
  }
}`