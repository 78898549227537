exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[blog]-blog-index-jsx": () => import("./../../../src/pages/blog/[blog]/index.jsx" /* webpackChunkName: "component---src-pages-[blog]-blog-index-jsx" */),
  "component---src-pages-[category]-category-index-jsx": () => import("./../../../src/pages/category/[category]/index.jsx" /* webpackChunkName: "component---src-pages-[category]-category-index-jsx" */),
  "component---src-pages-[dtc-detail]-dtc-detail-index-jsx": () => import("./../../../src/pages/dtc-detail/[dtc-detail]/index.jsx" /* webpackChunkName: "component---src-pages-[dtc-detail]-dtc-detail-index-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-add-collection-jsx": () => import("./../../../src/pages/add-collection.jsx" /* webpackChunkName: "component---src-pages-add-collection-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-category-index-jsx": () => import("./../../../src/pages/category/index.jsx" /* webpackChunkName: "component---src-pages-category-index-jsx" */),
  "component---src-pages-coming-soon-jsx": () => import("./../../../src/pages/coming-soon.jsx" /* webpackChunkName: "component---src-pages-coming-soon-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-dtc-detail-index-jsx": () => import("./../../../src/pages/dtc-detail/index.jsx" /* webpackChunkName: "component---src-pages-dtc-detail-index-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-jsx": () => import("./../../../src/pages/investors.jsx" /* webpackChunkName: "component---src-pages-investors-jsx" */),
  "component---src-pages-media-kit-jsx": () => import("./../../../src/pages/media-kit.jsx" /* webpackChunkName: "component---src-pages-media-kit-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */)
}

