import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import './theme-toggler.scss'
import { UseTheme } from '../../context/ThemeContext';

const Toggler = () => {

    const { updateTheme, theme } = UseTheme();

    return (
        <ThemeToggler>
            {({ toggleTheme }) => (
                <div className='checkbox-dark' >
                    <input type="checkbox" id="checkbox"
                        onChange={e =>
                            [updateTheme(theme === "light" ? "dark" : "light"),
                            toggleTheme(e.target.checked ? 'dark' : 'light')]
                        }
                        checked={theme === 'dark'} />
                    <label htmlFor="checkbox"><input type="text"/></label>
                </div>
            )}
        </ThemeToggler>
        // <ThemeToggler>
        //     {({ theme, toggleTheme }) => (
        //         <div className='checkbox-dark'>
        //             <input type="checkbox" id="switch"
        //                 onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
        //                 checked={theme === 'dark'} /><label htmlFor="switch"></label>
        //         </div>
        //     )}
        // </ThemeToggler>
    )
}
export default Toggler;