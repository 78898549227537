import React from 'react';
import RingLoader from "react-spinners/RingLoader";
import HubspotForm from 'react-hubspot-form'

const HubspotFormGeneral = props => {
    return (
        <HubspotForm
            portalId={props.portalId} 
            formId={props.formId} 
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={
                <div className="text-center ringer-hubspot">
                    <RingLoader color="#2a4379" />
                </div>
            }
        />
    );
};

export default HubspotFormGeneral;