import React, { useState } from "react";
import "@ethersproject/shims";
import Moralis from "moralis-v1";
import { ethers } from 'ethers';
import axios from 'axios';
import { useMoralis, useNativeBalance } from "react-moralis";
import { lazyMintAbi, lazyMintAddress } from "../utils/lazyMintContract";

const UserContext = React.createContext([]);

UserContext.displayName = "UserContext";

export const UserProvider = ({ children }) => {

    // useMoralis hook
    const { authenticate, isAuthenticated, logout, provider, user } = useMoralis();
    const { data: balance } = useNativeBalance({ chain: "polygon" });
    const [favorites, setFavorites] = useState([]);

    //estados para la funcion getMaxGas
    const [estimatedGasLimit, setEstimatedGasLimit] = useState();

    // funcion para conectarse a moralis usando web3Auth
    const connect = async () => {
        try {
            if (isAuthenticated) return

            const authentication = await authenticate({
                provider: "web3Auth",
                clientId: process.env.GATSBY_WEB3_ID,
                chainId: Moralis.Chains.POLYGON_MAINNET,
                loginMethodsOrder: ["google", "facebook", "github", "discord", "linkedin"],
                theme: "dark",
                appLogo: "https://digitaltradingcards.mypinata.cloud/ipfs/Qmafkqj5LdBUngyrR7eY2Doh23cYBxp18QpqXLE7Nzqaxu",
                signingMessage: "Connect to DTC website"
            });
            // si nos conectamos con metamask te pide que firmes nuevamente
            if (window.ethereum && !authentication) {
                const response = await authenticate({
                    provider: "web3Auth",
                    clientId: process.env.GATSBY_WEB3_ID,
                    chainId: Moralis.Chains.POLYGON_MAINNET,
                    loginMethodsOrder: ["google", "facebook", "github", "discord", "linkedin"],
                    theme: "dark",
                    appLogo: "https://digitaltradingcards.mypinata.cloud/ipfs/Qmafkqj5LdBUngyrR7eY2Doh23cYBxp18QpqXLE7Nzqaxu",
                    signingMessage: "Connect to DTC website"
                });
                return response
            };
            return authentication
        } catch (error) {
            console.log(error);
            throw new Error("Error en el login: " + error.message)
        }
    };

    //consigue el balance de MATIC
    const getMaticBalance = async () => {
        if (balance.balance) {
            const maticBalance = balance.balance
            const formatBalance = ethers.utils.formatEther(maticBalance)
            return formatBalance
        }
    }

    // maximo precio de gas en MATIC
    const getMaxGas = async () => {
        let feeData = await axios.get('https://gasstation-mainnet.matic.network/');
        let fastestGas = ethers.utils.parseUnits((feeData.data.fastest).toString(), "gwei");
        return fastestGas
    }

    // funcion para desconectarse de web3Auth y moralis
    const disconnect = async () => {
        await logout();
        setEthersProvider(undefined);
    }

    // provider que se va a usar solo en funciones de lectura de blockchain
    const onlyReadProvider = new ethers.providers.JsonRpcProvider("https://polygon-rpc.com");
    // provider que se va a usar para interactuar con contratos
    const [ethersProvider, setEthersProvider] = React.useState();

    const enableWeb3 = async () => {
        try {
            const response = await Moralis.enableWeb3({
                provider: "web3Auth",
                clientId: process.env.GATSBY_WEB3_ID,
                chainId: Moralis.Chains.POLYGON_MAINNET,
            });
            setEthersProvider(response)
        } catch (error) {
            console.error('Error en enableWeb3', error);
        }
    };

    //consigue un aproximado del precio del gas
    const maxGasFee = async (getDtc, amount) => {
        if (!ethersProvider) return
        const signer = ethersProvider.getSigner();
        const contract = new ethers.Contract(lazyMintAddress, lazyMintAbi, signer);

        if (getDtc && contract) {
            const fastestGas = await getMaxGas();
            if (getDtc.attributes.state === 3) {
                const constEstimatedGasLimit = (await contract.estimateGas.secondaryMint(
                    getDtc.attributes.tokenId,
                    getDtc.attributes.collectionAddress,
                    amount,
                    getDtc.id,
                    { value: "1000000000000000000" }
                )).toString()
                setEstimatedGasLimit(constEstimatedGasLimit)
            } else {
                const constEstimatedGasLimit = (await contract.estimateGas.primarySale(
                    [getDtc.attributes.json,
                    getDtc.attributes.creator,
                    getDtc.attributes.timestamp,
                    getDtc.attributes.collectionAddress],
                    getDtc.attributes.signature,
                    getDtc.id,
                    amount,
                    // valueString,
                    getDtc.attributes.totalSupply ? getDtc.attributes.totalSupply : 65535,
                    { value: "1000000000000000000" }
                    // {gasLimit: 29000}
                )).toString()
                console.log("constEstimatedGasLimit", constEstimatedGasLimit)
                setEstimatedGasLimit(constEstimatedGasLimit)
            }
            if (estimatedGasLimit) {
                const estimatedGasCost = fastestGas * estimatedGasLimit
                return ethers.utils.formatEther(estimatedGasCost.toString(), "gwei").toString()
            }
        }
    }

    React.useEffect(() => {
        if (!provider && isAuthenticated) {
            enableWeb3();
        } else if (provider && user) {
            const newProvider = new ethers.providers.Web3Provider(provider);
            setEthersProvider(newProvider);
        } else if (!isAuthenticated) {
            setEthersProvider(undefined)
        }
    }, [user, provider, isAuthenticated])

    /* Si hay cambio de cuenta cuando Metamask este conectado => se desloguea de Moralis */
    try {
        const { ethereum } = window;
        if (ethereum) ethereum.on('accountsChanged', async () => await disconnect())
    } catch (error) {
        console.error('Error al escuchar evento onChainChanged');
    }

    return (
        <UserContext.Provider value={{
            connect,
            disconnect,
            ethersProvider,
            onlyReadProvider,
            getMaxGas,
            getMaticBalance,
            balance,
            maxGasFee,
            favorites,
            setFavorites
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = React.useContext(UserContext);
    return context;
};