import React, { useState, useEffect } from "react"

export const ThemeContext = React.createContext([])

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("light")

    useEffect(() => {
        initalizeTheme();
    });

    const initalizeTheme = async => {
        let themeLocal = localStorage.getItem('theme');
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        console.log(prefersDark)

        if (!themeLocal && !prefersDark) {
          setTheme('light');
          console.log(theme)
        }
        else if (!themeLocal && prefersDark) {
            setTheme('dark');
        }
        else if (themeLocal === 'light') {
            setTheme('light');
        }
        else if (themeLocal === 'dark'){
            setTheme('dark');
        }
    }

    const updateTheme = async => {
        setTheme(prevState => !prevState);
    }

    return (
        <ThemeContext.Provider
            value={{
                theme,
                updateTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
} 

export const UseTheme = () => {
    const context = React.useContext(ThemeContext);
    return context;
};