import * as React from 'react';
import { useMoralis } from 'react-moralis';
import { Button, Dropdown } from 'react-bootstrap';
import { useUser } from '../../context/UserContext';
import { BiLogOut } from 'react-icons/bi'
import './ConnectButton.css'

import { UseTheme } from '../../context/ThemeContext';

const ConnectButton = () => {
    const { user } = useMoralis();
    const { connect, disconnect } = useUser();

    const { theme } = UseTheme()

    if (user && user?.attributes.ethAddress) return (
        <Dropdown className='dropdown-login'>
            <Dropdown.Toggle variant="success" id='wallet-dropdown' className='btn-wallet wallet-dropdown'>
                {user?.attributes.ethAddress.slice(0, 5) + '...' + user?.attributes.ethAddress.slice(37)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item variant="primary" onClick={disconnect} className='btn-wallet'>
                    {theme === 'dark' ?
                        (<BiLogOut style={{ color: '#ffffff', fontSize: '1.25rem' }} />)
                        :
                        (<BiLogOut style={{ color: '#2a427b', fontSize: '1.25rem' }} />)
                    }
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

    return (
        <>
            <Button variant="primary" onClick={connect} className='btn-wallet'>SIGN IN</Button>
        </>
    )
}

export default ConnectButton