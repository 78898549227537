import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import * as React from "react"
import './header.css'
import { useStaticQuery, graphql } from 'gatsby'
import ComingSoon from '../modales/coming-soon';
import '../../pages/styles/index.scss'
import ConnectButton from '../connect-button/ConnectButton';
import Toggle from '../theme-toggler/theme-toggler';
import { UseTheme } from '../../context/ThemeContext';
import { useState, useEffect } from 'react';

const Header = ({ children }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { theme } = UseTheme();
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    catchUrl();
  })

  const catchUrl = () => {
    let url = window.location.href
    let after_ = url.substring(url.indexOf('category/') + 9);
    if (after_.includes('/')) {
      let before_ = after_.substring(0, after_.indexOf('/'));
      if (!before_.match('/')) setSelectedCategory(before_);
    }
    else {
      setSelectedCategory(after_);
    }
  };

  const data = useStaticQuery(graphql`
  query HeaderQuery {
    allDatoCmsLayout {
      nodes {
        darkLogo {
          url
        }
        lightLogo {
          url
        }
        menuHeader {
          itemTitle
          subItems {
            slug
            title
          }
          withoutDropdown
          internalLink {
            title
            slug
          }
          itsExternalLink
          externalLink {
            url
            label
          }
        }
        categories {
          name
          slug
          darkIcon {
            url
          }
          lightIcon {
            url
          }
          destination
        }
        titleNeedHelp
            itemNeedHelp {
              label
              withoutDropdown
              internalLink {
                slug
                title
              }
              subItems {
                title
                slug
              }
            }
      }
    }
  }
  `)

  console.log(data)

  // <div style={{backgroundColor: color}} ...
  return (
    <div className='height-header'>
      <Navbar expand="lg" className='header-primero'>
        <Container>
          <a href="/">
            {theme === 'dark' ?
              (<img alt="Logo Header Footer" src={data.allDatoCmsLayout.nodes[0].darkLogo.url} />)
              :
              (<img alt="Logo Header Footer" src={data.allDatoCmsLayout.nodes[0].lightLogo.url} />)
            }
            <h1 className='d-none'>Digital Trading Cards</h1>
          </a>
          <span className='d-none-min-992'>
            <Toggle theme={theme} />
          </span>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={theme === 'dark' ? 'navbar-dark' : 'navbar-light'} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              {data.allDatoCmsLayout.nodes[0].menuHeader.map((itemsHeader, index) =>
                <span key={index}>
                  {
                    itemsHeader.withoutDropdown === true ?
                      (itemsHeader.itsExternalLink === true ?
                        (<Nav.Link href={itemsHeader.externalLink[0].url} target="_blank">{itemsHeader.externalLink[0].label}</Nav.Link>)
                        :
                        (<Nav.Link href={"/" + itemsHeader.internalLink.slug}>{itemsHeader.internalLink.title}</Nav.Link>)
                      )
                      :
                      (
                        <NavDropdown title={itemsHeader.itemTitle} id="basic-nav-dropdown" className=''>
                          {itemsHeader.subItems.map((subItems, index) => <Nav.Link key={index} href={"/" + subItems.slug}>{subItems.title}</Nav.Link>)}
                        </NavDropdown>
                      )
                  }
                </span>
              )
              }
              <NavDropdown title="Categories" id="basic-nav-dropdown" className='d-none-min-992'>
                {data.allDatoCmsLayout.nodes[0].categories.map((categories, index) =>
                  <Nav.Link key={index} href={"/category/" + categories.slug + "/#all"}>
                    {selectedCategory === categories.slug ?
                      (
                        (theme === 'dark') ?
                          (<span className='background-selected'><img alt="Logo Header Footer" src={categories.lightIcon.url} /></span>)
                          :
                          (<span className='background-selected'><img alt="Logo Header Footer" src={categories.darkIcon.url} /></span>)
                      ) : (
                        (theme === 'dark') ?
                          (<span className='background-no-selected'><img alt="Logo Header Footer" src={categories.darkIcon.url} /></span>)
                          :
                          (<span className='background-no-selected'><img alt="Logo Header Footer" src={categories.lightIcon.url} /></span>)
                      )
                    }
                    {categories.name}
                  </Nav.Link>)
                }
              </NavDropdown>
              <ConnectButton />
              <span className='d-none-max-991'>
                <Toggle theme={theme} />
              </span>
              <ComingSoon
                _title={"Hello!"}
                _body1={"Coming soon!"}
                _body2={"Come back Later!"}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar expand="lg" className='header-segundo'>
        <Container>
          <h3>Categories</h3>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              {
                data.allDatoCmsLayout.nodes[0].categories.map((categories, index) =>
                  <Nav.Link key={index} href={"/category/" + categories.slug + "/#all"}>
                    {selectedCategory === categories.slug ?
                      (
                        (theme === 'dark')
                          ?
                          (<span className='background-selected'><img alt="Logo Header Footer" src={categories.lightIcon.url} /></span>)
                          :
                          (<span className='background-selected'><img alt="Logo Header Footer" src={categories.darkIcon.url} /></span>)
                      )
                      :
                      (
                        (theme === 'dark')
                          ?
                          (<span className='background-no-selected'><img alt="Logo Header Footer" src={categories.darkIcon.url} /></span>)
                          :
                          (<span className='background-no-selected'><img alt="Logo Header Footer" src={categories.lightIcon.url} /></span>)
                      )
                    }
                    {categories.name}
                  </Nav.Link>
                )
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;