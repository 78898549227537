import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as React from "react"
import './modales.css'
import '../../pages/styles/index.scss'
import Countdown from 'react-countdown';
import { useStaticQuery, graphql } from 'gatsby'

export default function ComingSoon(props) {

    const data = useStaticQuery(graphql`
    query comingSoon {
        allDatoCmsSoon {
            nodes {
                title
              date(formatString: "MM/DD/YYYY")
            }
          }
        }
    `)

    const expired = new Date(data.allDatoCmsSoon.nodes[0].date)

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <div className='modal-contenido'>
                <Modal.Body className="coming-soon-modal">
                    <div className='title-count'>
                        <h3>
                            <div dangerouslySetInnerHTML={{ __html: data.allDatoCmsSoon.nodes[0].title }}></div>
                        </h3>
                        <Countdown date={expired}
                            renderer={renderer} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='background-buttons-home' onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return <div className='d-flex'>
        <div>
            <span>{days}
            </span>
            <hr></hr>
            <span>DAYS
            </span>
        </div>
        <div>
            <span>{hours}
            </span>
            <hr></hr>
            <span>HOURS
            </span>
        </div>
        <div>
            <span>{minutes}
            </span>
            <hr></hr>
            <span>MINUTES
            </span>
        </div>
        <div>
            <span>{seconds}
            </span>
            <hr></hr>
            <span>SECONDS
            </span>
        </div>
    </div>
};