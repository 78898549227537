import * as React from "react";
import { ethers } from 'ethers';
import Moralis from 'moralis-v1';
import { useUser } from './UserContext';
import { useNetwork } from './NetworkContext';
import { lazyMintAddress, lazyMintAbi } from '../utils/lazyMintContract';

const DTCsContext = React.createContext([]);

DTCsContext.displayName = "DTCsContext";

export const DTCsProvider = ({ children }) => {
    const { ethersProvider } = useUser();
    const { getMaxGas } = useNetwork();
    
    const log = async (_context, _level, _log, _input) => {
        await Moralis.Cloud.run("Log", {
            context: _context,
            level: _level,
            log: _log,
            input: _input,
        });
    }
    
    // obtener dtcs con todos los filtros disponibles
    
    const get_dtcs = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetDTCs", {
                // Filtros
                collection: filter._collection,
                state: filter._state,
                creator: filter._creator,
                owner: filter._owner,
                minPrice: filter._minPrice,
                maxPrice: filter._maxPrice,
                collectionAddress: filter._collectionAddress,
                exclude: filter._exclude,
                // listo para ser minteado
                readyToMint: filter._readyToMint,
                // random
                getRandom: filter._getRandom, // true or false
                quantity: filter._quantity,  // cantidad de randoms a traer
                // Paginacion
                offset: filter._offset,
                limit: filter._limit,
                // Ordenamiento
                sortBy: filter._sortBy,
                asc: filter._asc,
                // Busqueda 
                search: filter._search,
                // se saltea los formatos => solo devuelve el largo del array
                onlyLength: filter._onlyLength,
            });
            return response
        } catch (error) {
            // TODO: QUE NO TIRE MAS ERROR AL HACER REFRESH
            // throw new Error('Error getting the DTCs: ' + error)
        }
    };

    // obtener un solo dtc de muchas maneras
    const get_dtc = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetDTCs", {
                // listo para ser minteado
                readyToMint: filter._readyToMint,
                // filtros
                moralisId: filter._moralisId,
                tokenId: filter._tokenId,
                collectionAddress: filter._collectionAddress,
                marketplaceId: filter._marketplaceId,
                addCollection: filter._addCollection,
                addCategory: filter._addCategory,
                // random
                getRandom: filter._getRandom, // true or false
                quantity: 1,
                // Ordenamiento
                sortBy: filter._sortBy,
                asc: filter._asc,
                // Busqueda 
                search: filter._search,
                // al ser uno solo siempre va true
                onlyFirst: true,
            });
            return response
        } catch (error) {
            // TODO: QUE NO TIRE MAS ERROR AL HACER REFRESH
            // throw new Error('Error getting the DTC: ' + error)
        }
    };

    // obtener solo la cantidad de dtcs
    const get_dtcs_length = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetDTCs", {
                // Filtros
                collection: filter._collection,
                state: filter._state,
                creator: filter._creator,
                owner: filter._owner,
                minPrice: filter._minPrice,
                maxPrice: filter._maxPrice,
                collectionAddress: filter._collectionAddress,
                // listo para ser minteado
                readyToMint: filter._readyToMint,
                // random
                getRandom: filter._getRandom, // true or false
                quantity: filter._quantity,  // cantidad de randoms a traer
                // Paginacion
                offset: filter._offset,
                limit: filter._limit,
                // Ordenamiento
                sortBy: filter._sortBy,
                asc: filter._asc,
                // Busqueda 
                search: filter._search,
                // se saltea los formatos => solo devuelve el largo del array
                onlyLength: filter._onlyLength,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context getting all the Collections: ' + error.data ? error.data.message : error.message)
        }
    };

    // mintear dtc 1155
    // @params: json, creator, timestamp, collectionAddress, signature, moralisId, amount, maxSupply, price
    const mint_1155 = async (params) => {
        let myAddress;
        try {
            const signer = ethersProvider.getSigner();
            myAddress = await signer.getAddress();
            const fastestGas = await getMaxGas();
            let contract = new ethers.Contract(lazyMintAddress, lazyMintAbi, signer);

            const maticValue = await contract.computeAmount(params._collectionAddress);
            const bgNumber = ethers.BigNumber.from(params._amount);

            const totalValue = maticValue.mul(bgNumber);
            const slippage = totalValue.div(50);

            const sendValue = totalValue.add(slippage);

            // Nft memory _nftData,
            // bytes calldata signature,
            // string memory _moralisId,
            // uint256 _tokenAmount,
            // uint256 _maxSupply
            const tx = await contract.primarySale(
                [params._json, params._creator, params._timestamp, params._collectionAddress],
                params._signature,
                params._moralisId,
                params._amount,
                params._maxSupply,
                {
                    value: sendValue,
                    gasPrice: fastestGas,
                    gasLimit: 450000
                });
            await tx.wait();
            return { success: true }
        } catch (error) {
            await log("mint_1155", "error", error, {params, myAddress})
            return { success: false, error: error.data ? error.data.message : error.message }
        }
    };

    // comprar DTC con tarjeta de credito (stripe)
    const pay_with_card = async (params) => {
        let myAddress;
        try {
            const signer = ethersProvider.getSigner();
            myAddress = await signer.getAddress();
            const response = await Moralis.Cloud.run("createCheckout", {
                moralisId: params._moralisId,
                addressTo: myAddress,
                success_url: params._successUrl,
                cancel_url: params._cancelUrl,
                tokenAmount: params._tokenAmount,
                tokenId: params._tokenId,
            });
            return response;
        } catch (error) {
            await log("pay_with_card", "error", error, {params, myAddress})
            throw new Error("Error getting the NFT: " + error);
        }
    };

    // crear supply 1155
    // @params: tokenId, collectionAddress, amount, _moralisId, price
    const create_supply = async (_tokenId, _collectionAddress, _amount, _moralisId, rejectedModal) => {
        let myAddress;
        try {
            const signer = ethersProvider.getSigner();
            myAddress = await signer.getAddress();
            const fastestGas = await getMaxGas();

            let contract = new ethers.Contract(lazyMintAddress, lazyMintAbi, signer);
            const maticValue = await contract.computeAmount(_collectionAddress);
            const bgNumber = ethers.BigNumber.from(_amount);

            const totalValue = maticValue.mul(bgNumber);
            const slippage = totalValue.div(50);

            const sendValue = totalValue.add(slippage);

            const tx = await contract.secondaryMint(
                _tokenId,
                _collectionAddress,
                _amount,
                _moralisId,
                {
                    value: sendValue,
                    gasPrice: fastestGas,
                    gasLimit: 450000
                }
            );
            await tx.wait();

            // pa probar si esta funcionando web3auth
            // console.log(_tokenId, _amount, _moralisId, _price, _collectionAddress)
            // console.log(await signer.getAddress())

            return { success: true }
        } catch (error) {
            // rejectedModal()
            const response = await log("create_supply", "error", error, {_tokenId, _collectionAddress, _amount, _moralisId, myAddress})
            console.log(response)
            return { success: false, error: error.data ? error.data.message : error.message }
        }
    };

    // TODO: HACER LLAMADO A LA CLOUD FUNCTION DE GET FAVS

    // TODO: corregir nombre de funcion cuando este ready
    // mintear dtc 721
    // @params: json, creator, timestamp, collectionAddress, signature, moralisId
    const mint_721 = async (params, _amount) => {
        let myAddress;
        try {
            const signer = ethersProvider.getSigner();
            myAddress = await signer.getAddress();
            const fastestGas = await getMaxGas();
            let contract = new ethers.Contract(lazyMintAddress, lazyMintAbi, signer);
            // TODO: corregir nombre de funcion cuando este ready
            const tx = await contract.primarySale(
                [params._json, params._creator, params._timestamp, params._collectionAddress],
                params._signature,
                params._moralisId,
                {
                    gasPrice: fastestGas,
                    gasLimit: 450000
                }
            );
            await tx.wait();
            return { success: true }
        } catch (error) {
            console.error(error);
            await log("mint_721", "error", error, {params, _amount, myAddress})
            return { success: false, error: error.data ? error.data.message : error.message }
        }
    };

    // funcion que devuelve los dtcs dentro de la tabla "favorites"
    const get_favs = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetFavs", {
                states: filter._states,
                collectionAddress: filter._collectionAddress,
            });

            return response
        } catch (error) {
            // console.error(error);
            // throw new Error('Error in the context getting the DTCs in "favorites": ' + error.data ? error.data.message : error.message)
        }
    }

    return (
        <DTCsContext.Provider value={{
            get_dtcs,
            get_dtc,
            get_dtcs_length,
            mint_1155,
            pay_with_card,
            create_supply,
            mint_721,
            get_favs
        }}>
            {children}
        </DTCsContext.Provider>
    );
};

export const useDTCs = () => {
    const context = React.useContext(DTCsContext);
    return context;
};