import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './footer.css'
import { StaticQuery, graphql } from "gatsby"
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import { Stack } from "react-bootstrap";
import '../../pages/styles/index.scss'
import { UseTheme } from '../../context/ThemeContext';
import { StructuredText } from "react-datocms";
import polygonImgDark from '../../assets/images/Polygon_blockchain_logo_dark.png';
import polygonImgLight from '../../assets/images/Polygon_blockchain_logo_light.png';

import HubspotFormGeneral from '../hubspot-forms/hubspot-form-general';

import Dropdown from 'react-bootstrap/Dropdown';

const Footer = () => {

  const { theme } = UseTheme();

  return (
    <StaticQuery
      query={graphql`
      query FooterQuery {
        allDatoCmsLayout {
          nodes {
            darkLogo {
              url
            }
            lightLogo {
              url
            }
            categories {
              name
              slug
              destination
            }
            emailsAgreement {
              value
            }
            socialNetworks {
              id
              lightIcon {
                url
              }
              darkIcon {
                url
              }
              alt
              destination
            }
            titleNeedHelp
            itemNeedHelp {
              label
              withoutDropdown
              internalLink {
                slug
                title
              }
              subItems {
                title
                slug
              }
            }
            titleItemsMarketplace
            titleBlockIcons
          }
        }
      }
      `}
      render={data => (
        <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
          <section className='footer'>
            <MDBContainer className='text-center text-md-start'>
              <MDBRow className='mt-3'>
                <MDBCol xs="12" md="4" lg="4" xl="4" className='mx-auto mb-4'>
                  <h6 className='text-uppercase fw-bold mb-4'>
                    {theme === 'dark' ?
                      (<img alt="first explication" src={data.allDatoCmsLayout.nodes[0].darkLogo.url} />)
                      :
                      (<img alt="first explication" src={data.allDatoCmsLayout.nodes[0].lightLogo.url} />)
                    }
                  </h6>
                  {theme === 'dark' ?
                    (<HubspotFormGeneral portalId='23479554' formId='7b3d7ca5-eb33-478b-b714-b4783288e44d' />)
                    :
                    (<HubspotFormGeneral portalId='23479554' formId='55a59782-ceb0-423b-b462-46cbbe4c68d9' />)
                  }                
                  <div className='links-cookies'><StructuredText data={data.allDatoCmsLayout.nodes[0].emailsAgreement.value} /></div>
                </MDBCol>
                <MDBCol md="0" lg="2" xl="2"></MDBCol>
                <MDBCol xs="12" md="3" lg="2" xl="2" className='mx-auto mb-4'>
                  <h6 className='text-uppercase fw-bold mb-4 title-500'>{data.allDatoCmsLayout.nodes[0].titleItemsMarketplace}</h6>
                  {
                    data.allDatoCmsLayout.nodes[0].categories.map((categories, index) =>
                      <p className='mb-2' key={index}>
                        <a href={"/category/" + categories.slug + "/#all"} className='text-reset'>{categories.name}</a>
                      </p>
                    )
                  }
                </MDBCol>
                <MDBCol xs="12" md="3" lg="2" xl="2" className='mx-auto mb-4'>
                  <h6 className='text-uppercase fw-bold mb-4 title-500'>{data.allDatoCmsLayout.nodes[0].titleNeedHelp}</h6>

                  {data.allDatoCmsLayout.nodes[0].itemNeedHelp.map((item, index) =>
                    <div key={index} className='mb-2'>
                      {
                        item.withoutDropdown === true ?
                          (
                            <Dropdown>
                              <Dropdown.Toggle variant="success" className='dropdown-sinbackground text-reset' id="dropdown-basic">
                                {item.label}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {item.subItems.map((subItems, index) =>
                                  <Dropdown.Item key={index} href={"/" + subItems.slug}>{subItems.title}</Dropdown.Item>)
                                }
                                {/* <Dropdown.Item key={index} target="_blank" href="https://us18.list-manage.com/contact-form?u=5b21b8805d062a1248d2b919b&form_id=50538887a0ea889dea7a37f67fd5e8cb">Contact Us</Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          )
                          :
                          (<a href={"/" + item.internalLink.slug} className='text-reset'>{item.internalLink.title}</a>)
                      }
                    </div>
                  )
                  }
                </MDBCol>
                <MDBCol xs="12" md="2" lg="2" xl="2" className='mx-auto mb-4 join-us'>
                  <h6 className='text-uppercase fw-bold mb-4 title-500'>{data.allDatoCmsLayout.nodes[0].titleBlockIcons}</h6>
                  <Stack className='icons-social'>
                    {
                      data.allDatoCmsLayout.nodes[0].socialNetworks.map((socialNetworks, index) =>
                        <a target="_blank" rel="noreferrer" key={index} href={socialNetworks.destination}>
                          {theme === 'dark' ?
                            (<img alt={socialNetworks.alt} src={socialNetworks.darkIcon.url} />)
                            :
                            (<img alt={socialNetworks.alt} src={socialNetworks.lightIcon.url} />)
                          }
                        </a>
                      )
                    }
                  </Stack>
                  <div className='mt-5 polygon'>
                    <p className='text-uppercase mb-0 powered-by'>Powered By</p>
                    <a href="https://polygon.technology/" className='text-reset polygon'>
                      {theme === 'dark' ?
                        (<img className="img-fluid" alt="card-long-first" src={polygonImgDark} />)
                        :
                        (<img className="img-fluid" alt="card-long-first" src={polygonImgLight} />)
                      }
                    </a>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
          <div className='text-center p-4 last-footer'>
            <p className='mb-0 title-500 add-color'>© 2024 DTC Digital Trading Cards, Inc. and respective owners | patent pending</p>
          </div>
        </MDBFooter>
      )}
    />
  );
}

export default Footer;
